import axios from "axios";
import { useRef, useState } from "react";
import "./Login.css";
import RegconLogo from "../../assets/regcon.png";
import BuildingImage from "../../assets/building.png";
import DoctorImage from "../../assets/doctor.png";

const API_URL = process.env?.REACT_APP_API_URL || "";

function App() {
    const emailRef = useRef();
    const passwordRef = useRef();
    const [invalidLoginAttempted, setInvalidLoginAttempted] = useState(false)

    const handleLogin = (e) => {
        e.preventDefault()
        const email = emailRef.current.value;
        const password = passwordRef.current.value;
        console.log(API_URL);
        axios
            .post(`${API_URL}/login`, { email, password })
            .then((resp) => {
                // localStorage.setItem('authToken', resp.data.authToken);
                window.location.assign("/home");
            })
            .catch((resp) => {
                if (resp.response.status === 401) {
                    setInvalidLoginAttempted(true)
                } else {
                    alert("Error Logging In");
                }
            });
    };

    return (
        <div className='login-main-container'>
            <div className='login-display'>
                <div className='login-display-header'>
                    <a className='main-link' href={"/"}>
                        <img src={RegconLogo} alt={'RegCon'} />
                    </a>
                </div>
                <form onSubmit={handleLogin} className='login-box'>
                    <div className='login-box-title'>
                        <h1>Welcome to Regcon Software.</h1>
                        <p>
                            Log in and get access to software and license keys.
                        </p>
                    </div>
                    <label htmlFor='email'>Email *</label>
                    <input
                        type='username'
                        placeholder='Email'
                        name='username'
                        className='primary-input login-input'
                        ref={emailRef}
                        autoComplete='username'
                        required
                    />
                    <label htmlFor="password">Password *</label>
                    <input
                        type='password'
                        placeholder='Password'
                        name="password"
                        autoComplete='current-password'
                        className='primary-input login-input'
                        ref={passwordRef}
                        required
                    />

                    <input
                        //onClick={handleLogin}
                        className='primary-button login-input'
                        autoComplete="password"
                        type="submit"
                        value='Login'

                    />
                    <p>
                        Dont have an account? <a href={"/signup"}>Sign Up!</a>
                    </p>
                    {invalidLoginAttempted && (
                        <div className="invalid-login-message">
                            <p>Oops! It looks like your login attempt didn't work. Let's try again:</p>
                            <ul>
                                <li>Double-check your email address for any typos.</li>
                                <li>Make sure your password is correct (remember, it's case-sensitive).</li>
                                <li>If you're still having trouble, contact us for a new password.</li>
                            </ul>
                        </div>
                    )}
                </form>
            </div>
            <div className='login-decorator-panel'>
                <img src={DoctorImage} alt='' />
            </div>
        </div>

    );
}

export default App;
